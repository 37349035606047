import React, { useEffect, useState } from "react";

function Geolocation() {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState([]);

    useEffect(() => {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        const success = (position) => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude);
            setLongitude(longitude);
            setLocation([latitude, longitude]);
        };

        const failure = (error) => {
            setError(error.message);
        };

        navigator.geolocation.getCurrentPosition(success, failure, options);
    }, []);

    return location;
}

export default Geolocation;
