import React, { useState, useEffect } from "react";
import { startTickingTime } from "./DateTime";

function Clock() {
    const [time, setTime] = useState("");

    useEffect(() => {
        startTickingTime((currentTime) => {
            setTime(currentTime);
        });
    }, []);

    return (
        <div>
            <h1>{time}</h1>
        </div>
    );
}

export default Clock;
