import "./App.css";
import SearchListSection from "./SearchListSection";
import Weather from "./Weather";
import WeatherApp from "./WeatherApp";

function App() {
    return (
        <div className="App">
            
            <WeatherApp />
            {/* <SearchListSection /> */}
        </div>
    );
}

export default App;
