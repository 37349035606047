import Weather from "./Weather";

const img = "https://photo-cdn2.icons8.com/rgsfzrHlPyzshPOJznDsh7AQiX9upIhsD-ZaPzpo7O0/rs:fit:1608:1072/czM6Ly9pY29uczgu/bW9vc2UtcHJvZC5l/eHRlcm5hbC9hMmE0/Mi84YzYwOTMxY2M0/ZGI0MWIxYjA2OTI0/NmZlM2U1YWQxYi5q/cGc.jpg";

const WeatherApp = () => {

    const styles = {
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        color: 'white'
    };

    return (
        <div style={styles}>
            <Weather />
        </div>
    );
};

export default WeatherApp;
