import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, API_ENDPOINTS } from "./apiConstants";
import Geolocation from "./Geolocation";
import Clock from "./utils/Clock";

const Weather = () => {
    const [weatherData, setWeatherData] = useState(null);
    const [latitude, longitude] = Geolocation();
    const locationString = `${latitude}, ${longitude}`;
    console.log("location: ", locationString);

    useEffect(() => {
        async function fetchWeatherData() {
            if (latitude && longitude) {
                try {
                    const response = await axios.get(
                        `${BASE_URL}${API_ENDPOINTS.GET_CURRENT(
                            locationString
                        )}`
                    );
                    const data = response.data;
                    setWeatherData(data);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchWeatherData();
    }, [locationString, latitude, longitude]);

    if (!weatherData) {
        return <div>Loading weather data...</div>;
    }

    const { location, current } = weatherData;

    return (
        <div>
            <h2>Current weather in {location.name}, {location.region}</h2>
            <p style={{ fontSize: 40, fontWeight: "bold" }}>
                {current.temp_f} °F / {current.temp_c} °C
            </p>
            <Clock />
            <p>Condition: {current.condition.text}</p>
            <img src={current.condition.icon} alt={current.condition.text} />
        </div>
    );
};

export default Weather;
